import { createEffect } from 'effector';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { api } from './axiosClient';

export const signUpFx = createEffect(async ({ url, email, password }) => {
	const { data } = await api.post(url, { email, password });

	if (data.message) {
		toast.error(data.message);
		return { warningMessage: data.message };
	}

	toast.success('Registration was successful');
	return { data };
});

export const signInFx = createEffect(async ({ url, email, password }) => {
	const { data } = await api.post(url, { email, password });

	if (data.detail) {
		toast.error(data.detail);
		return { warningMessage: data.detail };
	}

	toast.success('The input is accomplished');
	localStorage.setItem('accessToken', data.access);
	Cookies.set('refresh_token', data.refresh);
	return { data };
});

export const refreshAccess = createEffect(async ({ url, refresh }) => {
	const { data } = await api.post(url, { refresh });

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});

// export const logoutFx = createEffect(async url => {
// 	try {
// 		await api.get(url);
// 	} catch (error) {
// 		toast.error(error.message);
// 	}
// });
