import styles from './ContactUsForm.module.scss';

export default function AgreeCheckbox({
	allowSendMessage,
	setAllowSendMessage,
}) {
	const handleCheckboxChange = () => {
		setAllowSendMessage(!allowSendMessage);
	};

	return (
		<label className={styles.Checkbox}>
			<input
				type='checkbox'
				checked={allowSendMessage}
				onChange={handleCheckboxChange}
			/>
			<span>
				I agree with the personal data <a href='#'>processing policy</a>
			</span>
		</label>
	);
}
