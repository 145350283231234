import { useStore } from 'effector-react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from './components/modules/AppLayout/AppLayout';
import ProtectedRoute from './components/modules/ProtectedRoute/ProtectedRoute';
import Toastify from './components/ui/toastify/Toastify';
import { $user } from './context/user';
import useConfirmNewEmail from './hooks/useConfirmNewEmail';
import useRefreshToken from './hooks/useRefreshToken';
import useUserCheck from './hooks/useUserCheck';
import Completion from './pages/Completion/Completion';
import { Contacts } from './pages/Contacts/Contacts';
import { HomePage } from './pages/HomePage/HomePage';
import { PaymentPage } from './pages/PaymentPage/PaymentPage';
import { ProfilePage } from './pages/ProfilePage/ProfilePage';
import { TennisCourts } from './pages/TennisCourts/TennisCourts';

function App() {
	useUserCheck();
	useConfirmNewEmail();
	useRefreshToken();
	const user = useStore($user);
	const isLoggedIn = user.email;

	return (
		<>
			<Toastify />
			<div className='App'>
				<Routes>
					<Route path='/' element={<AppLayout isLoggedIn={isLoggedIn} />}>
						<Route index element={<HomePage isLoggedIn={isLoggedIn} />} />
						<Route
							path='tennis-courts'
							element={<TennisCourts isLoggedIn={isLoggedIn} />}
						/>
						<Route
							path='profile'
							element={
								<ProtectedRoute element={ProfilePage} isLoggedIn={isLoggedIn} />
							}
						/>
						<Route path='contacts' element={<Contacts />} />
						<Route path='completion' element={<Completion />} />
						<Route
							path='payment'
							element={<PaymentPage isLoggedIn={isLoggedIn} />}
						/>
						<Route path='*' element={<HomePage />} />
					</Route>
				</Routes>
			</div>
		</>
	);
}

export default App;
