import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { unprotectedApi } from './axiosClient';

export const getAllProducts = createEffect(async ({ url }) => {
	const { data } = await unprotectedApi.get(url);

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});

export const getAllFilters = createEffect(async ({ url }) => {
	const { data } = await unprotectedApi.get(url);

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});
