import {
	checkTimeOfDay,
	extractTimeFromDateTime,
	formatDateV2,
} from '../../../utils/calendar';
import StripePayment from '../../modules/Payments/StripePayment/StripePayment';
import styles from './PaymentSection.module.scss';

export default function PaymentSection({ slot }) {
	const date = new Date(slot.value);
	const time = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	return (
		<div className={styles.PaymentSection}>
			<div className={styles.EditPaymentMethod}>
				<h4 className={styles.EditPaymentMethodTitle}>Payment Method</h4>
				{/* <button className={styles.EditPaymentMethodButton}>Edit</button> */}
			</div>
			<div className={styles.PaymentInfo}>
				<StripePayment isPage slotId={slot.id} />
			</div>
			<div className={styles.PaymentCheck}>
				<h5 className={styles.PaymentCheckTitle}>Your Order</h5>
				<div className={styles.PaymentCheckMainInfo}>
					<span className={styles.PaymentCheckName}>{slot.courtName}</span>
					<span className={styles.PaymentCheckAddress}>
						{slot.courtAddress}
					</span>
				</div>
				<ul className={styles.PaymentCheckMoreInfoList}>
					<li className={styles.PaymentCheckMoreInfoListItem}>
						<span>Date</span>
						<span>{formatDateV2(new Date(slot.value))}</span>
					</li>
					<li className={styles.PaymentCheckMoreInfoListItem}>
						<span>Time</span>
						<span>
							{slot.dayOfWeek}, {checkTimeOfDay(slot.value)} {time} −{' '}
							{extractTimeFromDateTime(slot.finishDate)}
						</span>
					</li>
					<li className={styles.PaymentCheckMoreInfoListItem}>
						<span>Price</span>
						<span>${slot.price}</span>
					</li>
				</ul>
			</div>
		</div>
	);
}
