import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { supportLetter } from '../../api/main';
import AgreeCheckbox from '../../components/ui/ContactUsForm/AgreeCheckbox';
import EmailInput from '../../components/ui/ContactUsForm/EmailInput';
import MessageTextarea from '../../components/ui/ContactUsForm/MessageTextarea';
import NameInput from '../../components/ui/ContactUsForm/NameInput';
import SubmitButton from '../../components/ui/ContactUsForm/SubmitButton';
import PageTitle from '../../components/ui/Titles/PageTitle/PageTitle';
import styles from './Contacts.module.scss';

export function Contacts() {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm();

	const [allowSendMessage, setAllowSendMessage] = useState(false);

	const onSubmit = async inputValue => {
		try {
			const { data } = await supportLetter({
				url: '/api/v1/support-letter',
				full_name: inputValue.name,
				email: inputValue.email,
				content: inputValue.message,
			});
			reset();
			toast.error(data.warningMessage);
		} catch (error) {
			toast.error('Oops! Error');
		}
	};

	return (
		<div className='container'>
			<div className={styles.Contacts}>
				<PageTitle title='Contacts' breadcrumbs />
				<div className={styles.ContactsInfo}>
					<p>
						We understand your frustration when you can't find a court that you
						like because it has been reserved by someone else. It's even more
						frustrating when the person who reserved it doesn't show up, making
						it worse for you. That's why we are here to help.
					</p>
					<p>
						Feel free to contact us and we will ensure your booking experience
						is memorable and enjoyable!
					</p>
				</div>

				<form className={styles.ContactsForm} onSubmit={handleSubmit(onSubmit)}>
					<NameInput register={register} errors={errors} />
					<EmailInput register={register} errors={errors} />
					<MessageTextarea register={register} errors={errors} />
					<AgreeCheckbox
						allowSendMessage={allowSendMessage}
						setAllowSendMessage={setAllowSendMessage}
					/>
					<SubmitButton allowSendMessage={allowSendMessage} name='Submit' />
				</form>
			</div>
		</div>
	);
}
