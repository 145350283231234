import { useEffect, useState } from 'react';

export function useMediaQuery(maxWidth) {
	const [isMedia, setIsMedia] = useState(
		window.matchMedia(`(max-width: ${maxWidth}px)`).matches
	);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(`(max-width: ${maxWidth}px)`);
		const handleChange = event => {
			setIsMedia(event.matches);
		};

		mediaQueryList.addListener(handleChange);

		return () => {
			mediaQueryList.removeListener(handleChange);
		};
	}, [maxWidth]);

	return isMedia;
}
