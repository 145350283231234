import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { confirmNewEmail } from '../api/account';

const useConfirmNewEmail = () => {
	const url = window.location.href;
	let uid = '';
	let token = '';

	if (url && url.includes('/email/reset/confirm/')) {
		const parts = url.split('/email/reset/confirm/')[1]?.split('/');
		if (parts.length === 2) {
			uid = parts[0];
			token = parts[1];
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			if (uid && token) {
				try {
					await confirmNewEmail({
						url: '/api/v1/accounts/confirm-new-email',
						uid: uid,
						token: token,
					});
					toast.success('Email has been successfully changed!');
				} catch (error) {
					toast.error(`There's been an error, mail has not been changed`);
				}
			}
		};

		fetchData();
	}, [uid, token]);
};

export default useConfirmNewEmail;
