import { useId, useState } from 'react';
import Select, { components } from 'react-select';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import ArrowIcon from './icons/ArrowIcon';
import {
	controlStyles,
	menuStyles,
	optionsStyles,
	selectStyles,
} from './styles';

export default function StandardSelect({
	options,
	placeholder,
	disabled,
	onClick,
	onChange,
	select,
}) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isMedia480 = useMediaQuery(480);
	const isMedia1024 = useMediaQuery(1024);

	const DropdownIndicator = ({ ...props }) => {
		const rotateDegrees = isMenuOpen ? 180 : 0;
		return (
			<components.DropdownIndicator {...props}>
				<ArrowIcon style={{ transform: `rotate(${rotateDegrees}deg)` }} />
			</components.DropdownIndicator>
		);
	};

	return (
		<div onClick={onClick}>
			<Select
				onChange={onChange}
				options={options}
				components={{ DropdownIndicator }}
				isSearchable={false}
				instanceId={useId()}
				placeholder={placeholder || 'Select'}
				isDisabled={disabled}
				styles={{
					...selectStyles({ select, isMedia480 }),
					control: defaultStyles => ({
						...controlStyles({ defaultStyles, isMedia1024 }),
					}),
					input: defaultStyles => ({
						...defaultStyles,
					}),
					menu: defaultStyles => ({
						...menuStyles(defaultStyles),
					}),
					option: defaultStyles => ({
						...optionsStyles(defaultStyles),
					}),
				}}
				onMenuOpen={() => setIsMenuOpen(true)}
				onMenuClose={() => setIsMenuOpen(false)}
			/>
		</div>
	);
}
