import { useStore } from 'effector-react';
import { Link, useLocation } from 'react-router-dom';
import { $user } from '../../../../context/user';
import { exceptionPages } from '../../../../data/pages';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import ProfileIcon from './icons/ProfileIcon';
import styles from './ProfileButton.module.scss';

export default function ProfileButton() {
	const location = useLocation();
	const user = useStore($user);
	const isMedia960 = useMediaQuery(960);

	return (
		<Link
			to='/profile'
			className={styles.ProfileButton}
			style={{
				color: exceptionPages.includes(location.pathname) ? '' : 'white',
			}}
		>
			{!isMedia960 ? (
				user.email
			) : (
				<ProfileIcon
					color={exceptionPages.includes(location.pathname) ? '' : 'white'}
				/>
			)}
		</Link>
	);
}
