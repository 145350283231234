import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { exceptionPages } from '../../../../data/pages';
import useActivationCheck from '../../../../hooks/useActivationCheck';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import AuthModal from '../../../modules/Modals/AuthModal/AuthModal';
import SignInIcon from './icons/SignInIcon';
import styles from './SignInButton.module.scss';

export default function SignInButton({ error }) {
	const location = useLocation();
	const [showModal, setShowModal] = useState(false);
	const isMedia960 = useMediaQuery(960);

	useActivationCheck(setShowModal);

	return (
		<>
			<button
				className={styles.SignInButton}
				style={{
					color: exceptionPages.includes(location.pathname) ? '' : 'white',
				}}
				onClick={() => setShowModal(!showModal)}
			>
				<SignInIcon
					color={exceptionPages.includes(location.pathname) ? '' : 'white'}
				/>
				{!isMedia960 ? 'Log in' : ''}
			</button>

			<AuthModal
				showModal={showModal}
				setShowModal={setShowModal}
				error={error}
			/>
		</>
	);
}
