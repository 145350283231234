import { Link } from 'react-router-dom';
import styles from './FailedCompletion.module.scss';
import FailedIcons from './icons/FailedIcons';

export default function FailedCompletion() {
	return (
		<div className={styles.FailedCompletion}>
			<div className={styles.ResultFaildedCompletion}>
				<FailedIcons />
				<h2 className={styles.ResultFailedCompletionTitle}>Oops!</h2>
				<h4 className={styles.ResultFailedCompletionSubTitle}>
					Something went wrong.
				</h4>
				<p className={styles.ResultFailedCompletionInfo}>
					Try again or contact us.
				</p>
			</div>
			<div className={styles.FailedCompletionLinks}>
				<Link to='/tennis-courts' className={styles.FailedCompletionLink}>
					Back
				</Link>
				<Link
					to='/contacts'
					className={`${styles.FailedCompletionLink} ${styles.FailedCompletionLinkBlack}`}
				>
					Contact us
				</Link>
			</div>
		</div>
	);
}
