import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, ...props }) => {
	const { pathname } = useLocation();
	return props.isLoggedIn ? (
		<Component {...props} />
	) : (
		<Navigate to='/' state={{ backUrl: pathname }} replace />
	);
};

export default ProtectedRoute;
