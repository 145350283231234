import { useEffect, useState } from 'react';
import FailedCompletion from '../../components/modules/Payments/Completion/FailedCompletion/FailedCompletion';
import SuccessfulCompletion from '../../components/modules/Payments/Completion/SuccessfulCompletion/SuccessfulCompletion';
import styles from './Completion.module.scss';

export default function Completion() {
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		const url = new URL(window.location.href);
		const redirectStatus = url.searchParams.get('redirect_status');

		if (redirectStatus === 'succeeded') {
			setSuccess(true);
		} else {
			setSuccess(false);
		}
	}, []);
	return (
		<div className='container'>
			<section className={styles.Completion}>
				{success ? <SuccessfulCompletion /> : <FailedCompletion />}
			</section>
		</div>
	);
}
