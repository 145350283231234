import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { api } from './axiosClient';

export const checkActivationAccount = createEffect(
	async ({ url, uid, token }) => {
		const { data } = await api.post(url, { uid, token });

		if (data.message) {
			toast.warning(data.message);
			return { warningMessage: data.message };
		}

		toast.success('Activation was successful!');
		return { data };
	}
);

export const getUserData = createEffect(async ({ url }) => {
	const { data } = await api.get(url);

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});

export const updateAccountInfo = createEffect(
	async ({ url, first_name, last_name, avatar }) => {
		const { data } = await api.patch(url, { first_name, last_name, avatar });

		if (data.message) {
			toast.warning(data.message);
			return { warningMessage: data.message };
		}

		return { data };
	}
);

export const changePassword = createEffect(
	async ({ url, old_password, new_password }) => {
		const { data } = await api.post(url, { old_password, new_password });

		if (data.message) {
			toast.warning(data.message);
			return { warningMessage: data.message };
		}

		return { data };
	}
);

export const changeEmail = createEffect(async ({ url, new_email }) => {
	const { data } = await api.post(url, { new_email });

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});

export const confirmNewEmail = createEffect(async ({ url, uid, token }) => {
	const { data } = await api.post(url, { uid, token });

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});
