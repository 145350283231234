import axios from 'axios';

const token = localStorage.getItem('accessToken');

export const api = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
	headers: {
		Authorization: token ? `Bearer ${token}` : '',
	},
});

export const unprotectedApi = axios.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
});
