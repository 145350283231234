import styles from './ContactUsForm.module.scss';

export default function NameInput({ register, errors }) {
	return (
		<label className={styles.Label}>
			Your name
			<input
				{...register('name', {
					required: 'Please enter a name.',
					minLength: 3,
					maxLength: 20,
				})}
				className={styles.Input}
				type='text'
			/>
			{errors.name && (
				<span className={styles.ErrorAlert}>{errors.name.message}</span>
			)}
			{errors.name && errors.name.type === 'minLength' && (
				<span className={styles.ErrorAlert}>minimum of 3 symbols</span>
			)}
			{errors.name && errors.name.type === 'maxLength' && (
				<span className={styles.ErrorAlert}>minimum of 20 symbols</span>
			)}
		</label>
	);
}
