import styles from './ContactUsForm.module.scss';

export default function SubmitButton({ name, allowSendMessage }) {
	return (
		<button
			className={`${styles.Button} ${
				!allowSendMessage ? styles.DisabledButton : ''
			}`}
			disabled={!allowSendMessage}
			type='submit'
		>
			{name}
		</button>
	);
}
