export default function CameraIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='31'
			height='30'
			viewBox='0 0 31 30'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.7187 2.6573C9.13578 2.6572 7.78482 3.80145 7.52444 5.36278L7.0728 8.07096C6.97444 8.66076 6.46412 9.09303 5.86617 9.09303H5.3969C3.60853 9.09303 2.15876 10.5428 2.15876 12.3312V24.6029C2.15876 26.3913 3.60853 27.841 5.3969 27.841L25.5443 27.841C27.3326 27.841 28.7824 26.3914 28.7825 24.6031L28.7832 12.3314C28.7833 10.5429 27.3335 9.09303 25.5451 9.09303H25.1113C24.511 9.09303 23.9994 8.6574 23.9037 8.06474L23.4701 5.37981C23.2168 3.81109 21.8627 2.65797 20.2736 2.65787L10.7187 2.6573ZM5.39509 5.00767C5.82905 2.40547 8.08066 0.498376 10.7188 0.498535L20.2737 0.499111C22.9221 0.49927 25.1791 2.42114 25.6013 5.03568L25.9098 6.9464C28.7205 7.13398 30.9422 9.47332 30.942 12.3315L30.9412 24.6032C30.9411 27.5837 28.5248 29.9998 25.5443 29.9998L5.3969 29.9998C2.41627 29.9998 0 27.5835 0 24.6029V12.3312C0 9.45957 2.24275 7.11179 5.07219 6.94388L5.39509 5.00767Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.4695 13.1721C13.162 13.1721 11.2913 15.0427 11.2913 17.3502C11.2913 19.6578 13.162 21.5284 15.4695 21.5284C17.7771 21.5284 19.6477 19.6578 19.6477 17.3502C19.6477 15.0427 17.7771 13.1721 15.4695 13.1721ZM9.13257 17.3502C9.13257 13.8505 11.9697 11.0133 15.4695 11.0133C18.9693 11.0133 21.8064 13.8505 21.8064 17.3502C21.8064 20.85 18.9693 23.6872 15.4695 23.6872C11.9697 23.6872 9.13257 20.85 9.13257 17.3502Z'
				fill='white'
			/>
		</svg>
	);
}
