import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { refreshAccess } from '../api/auth';

const useRefreshToken = () => {
	const refreshToken = Cookies.get('refresh_token');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await refreshAccess({
					url: '/api/v1/auth/jwt/refresh',
					refresh: refreshToken,
				});
				localStorage.setItem('accessToken', data.access);
			} catch (error) {
				console.error('Refresh Error: ', error);
			}
		};

		fetchData();

		const intervalId = setInterval(fetchData, 5 * 60 * 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);
};

export default useRefreshToken;
