import { useEffect } from 'react';
import { checkActivationAccount } from '../api/account';

const useActivationCheck = setShowAuthModal => {
	const url = window.location.href;
	let uid = '';
	let token = '';

	if (url && url.includes('/activation/')) {
		const parts = url.split('/activation/')[1]?.split('/');
		if (parts.length === 2) {
			uid = parts[0];
			token = parts[1];
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			if (uid && token) {
				try {
					await checkActivationAccount({
						url: '/api/v1/accounts/activation',
						uid: uid,
						token: token,
					});
					setShowAuthModal(true);
				} catch (error) {
					console.error(error);
				}
			}
		};

		fetchData();
	}, [uid, token]);
};

export default useActivationCheck;
