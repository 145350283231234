import styles from './AuthForm.module.scss';

export default function EmailInput({ register, errors }) {
	return (
		<label className={styles.Label}>
			Email
			<input
				{...register('email', {
					required: 'Please enter a email.',
					pattern: {
						value: /\S+@\S+\.\S+/,
						message: 'Invalid Email!',
					},
				})}
				className={styles.Input}
				type='email'
			/>
			{errors.email && (
				<span className={styles.ErrorAlert}>{errors.email.message}</span>
			)}
		</label>
	);
}
