import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { exceptionPages, navigationPages } from '../../../data/pages';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import HeaderMobileMenu from '../../modules/HeaderMobileMenu/HeaderMobileMenu';
import ProfileButton from '../../ui/Buttons/profile-button/ProfileButton';
import SignInButton from '../../ui/Buttons/signin-button/SignInButton';
import Logo from '../../ui/Logo/Logo';
import styles from './Header.module.scss';
import BurgerMenuIcon from './icons/BurgerMenuIcon';

export default function Header({ isLoggedIn, error }) {
	const location = useLocation();
	const isMedia960 = useMediaQuery(960);
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const setActive = ({ isActive }) => (isActive ? styles.LinkActive : '');

	return (
		<header
			className={`${styles.Header} ${
				exceptionPages.includes(location.pathname) ? '' : styles.HeaderHome
			}`}
		>
			<div className='container'>
				<div className={styles.HeaderInner}>
					<Logo
						color={exceptionPages.includes(location.pathname) ? '' : 'white'}
					/>
					{!isMedia960 ? (
						<nav className={styles.HeaderMenu}>
							<ul className={styles.HeaderMenuList}>
								{navigationPages.map(page => (
									<li key={page.slug} className={styles.HeaderMenuListItem}>
										<NavLink to={page.slug} className={setActive}>
											{page.name}
										</NavLink>
									</li>
								))}
							</ul>
						</nav>
					) : (
						<HeaderMobileMenu
							showMobileMenu={showMobileMenu}
							setShowMobileMenu={setShowMobileMenu}
						/>
					)}

					<div className={styles.HeaderButtons}>
						{isLoggedIn ? <ProfileButton /> : <SignInButton error={error} />}
						{isMedia960 && (
							<button
								className={styles.OpenMobileMenuButton}
								onClick={() => setShowMobileMenu(!showMobileMenu)}
							>
								<BurgerMenuIcon
									color={
										exceptionPages.includes(location.pathname) ? '' : 'white'
									}
								/>
							</button>
						)}
					</div>
				</div>
			</div>
		</header>
	);
}
