export default function ArrowIcon({ active }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ transition: 'rotate 0.3s', rotate: active ? '180deg' : '0deg' }}
		>
			<path
				d='M4 8L12 16L20 8'
				stroke='#131722'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
