import { useEffect } from 'react';
import { getUserData } from '../api/account';
import { setUser } from '../context/user';

const useUserCheck = () => {
	const token = localStorage.getItem('accessToken');
	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await getUserData({
					url: '/api/v1/accounts/users-me',
				});
				setUser(data);
			} catch (error) {}
		};

		fetchData();
	}, [token]);
};

export default useUserCheck;
