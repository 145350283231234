import ProfileSection from '../../components/sections/ProfileSection/ProfileSection';
import PageTitle from '../../components/ui/Titles/PageTitle/PageTitle';
import styles from './ProfilePage.module.scss';

export function ProfilePage() {
	return (
		<div className='container'>
			<div className={styles.ProfilePage}>
				<PageTitle title='My account' breadcrumbs />
				<ProfileSection />
			</div>
		</div>
	);
}
