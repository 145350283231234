export const navigationPages = [
	{
		name: 'Home',
		slug: '/',
	},
	{
		name: 'Tennis courts',
		slug: '/tennis-courts',
	},
	{
		name: 'Contacts',
		slug: '/contacts',
	},
];

export const exceptionPages = [
	'/tennis-courts',
	'/profile',
	'/contacts',
	'/payment',
	'/completion',
];
