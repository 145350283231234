import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styles from './CheckoutForm.module.scss';

export default function CheckoutForm({ isPage }) {
	const stripe = useStripe();
	const elements = useElements();

	const [isProcessing, setIsProcessing] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsProcessing(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/completion`,
			},
		});

		if (error) {
			toast.error('Failed to make payment');
		}

		setIsProcessing(false);
	};

	return (
		<form id='payment-form' onSubmit={handleSubmit}>
			<PaymentElement />
			<button
				className={`${
					isPage ? styles.PaymentSectionButton : styles.CheckoutFormButton
				}`}
			>
				{isProcessing ? 'Processing ...' : 'Pay now'}
			</button>
		</form>
	);
}
