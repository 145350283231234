import { Outlet, useLocation } from 'react-router-dom';
import { exceptionPages } from '../../../data/pages';
import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';

export default function AppLayout({ isLoggedIn, error }) {
	const location = useLocation();

	return (
		<div className='wrapper'>
			<Header isLoggedIn={isLoggedIn} error={error} />
			<main className='main'>
				<Outlet />
			</main>
			{exceptionPages.includes(location.pathname) ? <Footer /> : ''}
		</div>
	);
}
