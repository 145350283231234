import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { signInFx } from '../../../../api/auth';
import EmailInput from '../../../ui/AuthForm/EmailInput';
import PasswordInput from '../../../ui/AuthForm/PasswordInput';
import SubmitButton from '../../../ui/AuthForm/SubmitButton';
import styles from './SignInForm.module.scss';

export default function SignInForm({ setShowModal }) {
	const {
		register,
		formState: { errors },
		handleSubmit,
		resetField,
		control,
	} = useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = async inputValue => {
		try {
			setLoading(true);
			const { data } = await signInFx({
				url: '/api/v1/auth/jwt/create',
				email: inputValue.email,
				password: inputValue.password,
			});
			resetField('email');
			resetField('password');
			setShowModal(false);
			toast.error(data.warningMessage);
			window.location.reload();
		} catch (error) {
			toast.error('An error occurred');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={styles.SignInFormBox}>
			{/* <AuthViaSocialNetwork />
			<span className={styles.SignInSeparator}>or</span> */}
			<form className={styles.SignInForm} onSubmit={handleSubmit(onSubmit)}>
				<EmailInput register={register} errors={errors} />
				<PasswordInput
					register={register}
					errors={errors}
					control={control}
					isLogin
				/>
				<SubmitButton loading={loading} name='Log in' />
			</form>
			<p className={styles.SignInWarning}>
				By clicking the “Sign up” button, you are creating a Tennis Сodes
				account and therefore you agree to Tennis Сodes Company’s Terms of Use
				and Privacy Policy.
			</p>
		</div>
	);
}
