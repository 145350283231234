import { Link } from 'react-router-dom';
import { navigationPages } from '../../../data/pages';
import Logo from '../../ui/Logo/Logo';
import styles from './Footer.module.scss';

export default function Footer() {
	return (
		<footer className={styles.Footer}>
			<div className='footer-container'>
				<div className={styles.FooterInner}>
					<div className={styles.FooterTop}>
						<Logo color='white' />
						<nav className={styles.FooterMenu}>
							<ul className={styles.FooterMenuList}>
								{navigationPages.map(page => (
									<li key={page.slug} className={styles.FooterMenuListItem}>
										<Link to={page.slug}>{page.name}</Link>
									</li>
								))}
							</ul>
						</nav>
						<Link to='/contacts' className={styles.FooterContactUsButton}>
							Write to us
						</Link>
					</div>
					<div className={styles.FooterBottom}>
						<span className={styles.FooterCopy}>Made in San Francisco, CA</span>
						{/* <ul className={styles.FooterBottomLinkList}>
							<li className={styles.FooterBottomLinkListItem}>
								<a href='#' className={styles.FooterBottomLink}>
									Terms
								</a>
							</li>
							<li className={styles.FooterBottomLinkListItem}>
								<a href='#' className={styles.FooterBottomLink}>
									Privacy
								</a>
							</li>
						</ul> */}
					</div>
				</div>
			</div>
		</footer>
	);
}
