import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { navigationPages } from '../../../data/pages';
import styles from './HeaderMobileMenu.module.scss';
import CrossIcon from './icons/CrossIcon';

export default function HeaderMobileMenu({
	showMobileMenu,
	setShowMobileMenu,
}) {
	const setActive = ({ isActive }) => (isActive ? styles.MobileLinkActive : '');

	useEffect(() => {
		if (showMobileMenu) {
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100dvh';
			// document.body.style.minHeight = 'initial';
		} else {
			document.body.style.overflow = '';
			document.body.style.height = '';
			// document.body.style.minHeight = '';
		}
	}, [showMobileMenu]);

	return (
		<div
			className={styles.HeaderMobileMenu}
			style={{
				translate: showMobileMenu ? '0 0' : '-150% 0',
			}}
			// style={{
			// 	display: showMobileMenu ? 'block' : 'none',
			// }}
		>
			<button
				className={styles.CloseHeaderMobileMenuButton}
				onClick={() => setShowMobileMenu(!showMobileMenu)}
			>
				<CrossIcon />
			</button>
			<ul className={styles.HeaderMobileMenuList}>
				{navigationPages.map(page => (
					<li key={page.slug} className={styles.HeaderMobileMenuListItem}>
						<NavLink
							to={page.slug}
							className={setActive}
							onClick={() => setShowMobileMenu(false)}
						>
							{page.name}
						</NavLink>
					</li>
				))}
			</ul>
		</div>
	);
}
