import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { unprotectedApi } from './axiosClient';

export const supportLetter = createEffect(
	async ({ url, full_name, email, content }) => {
		const { data } = await unprotectedApi.post(url, {
			full_name,
			email,
			content,
		});

		if (data.detail) {
			toast.error(data.detail);
			return { warningMessage: data.detail };
		}

		toast.success('The message has been sent!');
		return { data };
	}
);
