import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './BookButton.module.scss';

export default function BookButton({ isLoggedIn }) {
	const [show, setShow] = useState(false);

	return (
		<>
			{/* onClick={() => setShow(!show)} */}
			<Link to='/tennis-courts' className={styles.BookButton}>
				Book Now
			</Link>

			{/* <BookMenu isLoggedIn={isLoggedIn} show={show} setShow={setShow} /> */}
		</>
	);
}
