import { useEffect } from 'react';
import BookButton from '../../components/ui/Buttons/book-button/BookButton';
import styles from './HomePage.module.scss';

export function HomePage({ isLoggedIn }) {
	useEffect(() => {
		document.documentElement.classList.add('home-page');
		return () => {
			document.documentElement.classList.remove('home-page');
		};
	}, []);

	return (
		<div className={styles.HomeBackground}>
			<div className={styles.HomePageBackground} />
			<div className='container'>
				<div className={styles.BookNow}>
					<h1 className={styles.BookNowTitle}>
						Book last-minute tennis courts
					</h1>
					<p className={styles.BookNowDescription}>
						Get unique access to bookings of those who cannot attend
					</p>
					<BookButton isLoggedIn={isLoggedIn} />
				</div>
			</div>
		</div>
	);
}
