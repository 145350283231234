import styles from './ContactUsForm.module.scss';

export default function MessageTextarea({ register, errors }) {
	return (
		<label className={styles.Label}>
			Message
			<textarea
				{...register('message', {
					required: 'Please enter message.',
					minLength: 20,
					maxLength: 300,
				})}
				maxLength={300}
				className={styles.Textarea}
				rows='4'
				cols='50'
			/>
			{errors.message && (
				<span className={styles.ErrorAlert}>{errors.message?.message}</span>
			)}
			{errors.message && errors.message.type === 'minLength' && (
				<span className={styles.ErrorAlert}>minimum of 20 symbols</span>
			)}
			{errors.message && errors.message.type === 'maxLength' && (
				<span className={styles.ErrorAlert}>minimum of 300 symbols</span>
			)}
		</label>
	);
}
