export default function FailedIcons() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='100'
			height='100'
			viewBox='0 0 100 100'
			fill='none'
		>
			<path
				d='M50 66.6667H50.0417M50 33.3333V50M50 87.5C70.7107 87.5 87.5 70.7107 87.5 50C87.5 29.2893 70.7107 12.5 50 12.5C29.2893 12.5 12.5 29.2893 12.5 50C12.5 70.7107 29.2893 87.5 50 87.5Z'
				stroke='#FF5F00'
				strokeWidth='3'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
