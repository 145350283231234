import Cookies from 'js-cookie';
import { Navigate } from 'react-router';
import PaymentSection from '../../components/sections/PaymentSection/PaymentSection';
import PageTitle from '../../components/ui/Titles/PageTitle/PageTitle';
import styles from './PaymentPage.module.scss';

export function PaymentPage({ isLoggedIn }) {
	const slot = JSON.parse(Cookies.get('selected-slot'));

	return slot && isLoggedIn ? (
		<div className='container'>
			<div className={styles.PaymentPage}>
				<PageTitle title='Payment info' breadcrumbs />
				<PaymentSection slot={slot} />
			</div>
		</div>
	) : (
		<Navigate to='/' replace />
	);
}
