import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { api } from './axiosClient';

export const createOrder = createEffect(async ({ url, slot }) => {
	const { data } = await api.post(url, { slot });

	if (data.message) {
		toast.warning(data.message);
		return { warningMessage: data.message };
	}

	return { data };
});
