import { useState } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import useRefreshToken from '../../../hooks/useRefreshToken';
import TennisCourtsFilters from '../../modules/TennisCourts/TennisCourtsFilters/TennisCourtsFilters';
import TennisCourtsList from '../../modules/TennisCourts/TennisCourtsList/TennisCourtsList';
import styles from './TennisCourtsSection.module.scss';

export default function TennisCourtsSection({ isLoggedIn }) {
	const [courts, setCourts] = useState([]);
	const [newCourts, setNewCourts] = useState(null);
	const isMedia1200 = useMediaQuery(1200);
	const isMedia640 = useMediaQuery(640);

	const newCourtsSize = isMedia1200 ? 3 : isMedia640 ? 2 : 4;
	const courtsSize = newCourtsSize * 3;

	useRefreshToken();

	let url = `/api/v1/product/list?page_size=${courtsSize}`;

	return (
		<section className={styles.TennisCourtsSection}>
			<TennisCourtsFilters
				url={url}
				setCourts={setCourts}
				setNewCourts={setNewCourts}
				newCourtsSize={newCourtsSize}
			/>
			<TennisCourtsList
				courts={courts}
				setCourts={setCourts}
				newCourts={newCourts}
				setNewCourts={setNewCourts}
				isLoggedIn={isLoggedIn}
				url={url}
				newCourtsSize={newCourtsSize}
			/>
		</section>
	);
}
