import { useEffect, useRef, useState } from 'react';
import SignInForm from '../../Forms/SignInForm/SignInForm';
import SignUpForm from '../../Forms/SignUpForm/SignUpForm';
import styles from './AuthModal.module.scss';
import CrossIcon from './icons/CrossIcon';

export default function AuthModal({ showModal, setShowModal, error }) {
	const [isSignIn, setIsSignIn] = useState(true);
	const [overlayZIndex, setOverlayZIndex] = useState(null);
	const modalRef = useRef(null);
	const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

	const handleClickOutside = event => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setShowModal(false);
			setRegistrationSuccessful(false);
		}
	};

	const handleCloseModal = () => {
		setShowModal(!showModal);
		setRegistrationSuccessful(false);
	};

	useEffect(() => {
		if (showModal) {
			document.addEventListener('mousedown', handleClickOutside);
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100dvh';
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
			document.body.style.overflow = '';
			document.body.style.height = '';
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showModal]);

	useEffect(() => {
		if (!showModal) {
			const timeoutId = setTimeout(() => {
				setOverlayZIndex(-10);
			}, 300);

			return () => {
				clearTimeout(timeoutId);
			};
		} else {
			setOverlayZIndex(40);
		}
	}, [showModal]);

	return (
		<div className={styles.AuthModalOverlay} style={{ zIndex: overlayZIndex }}>
			<div
				className={styles.AuthModal}
				style={{
					transform: showModal
						? 'translate(-50%, -50%)'
						: 'translate(-50%, -200%)',
				}}
				ref={modalRef}
			>
				{registrationSuccessful ? (
					<div className={styles.SignUpSuccessfullBox}>
						<h4 className={styles.SignUpSuccessfullTitle}>Successful!</h4>
						<p className={styles.SignUpSuccessfullText}>
							Verify your email address, and confirm your account.
						</p>
					</div>
				) : (
					<>
						<h2 className={styles.AuthModalTitle}>
							{isSignIn ? 'Log in' : 'Create an account'}
						</h2>
						<button
							className={styles.AuthModalCloseButton}
							onClick={handleCloseModal}
						>
							<CrossIcon />
						</button>
						{isSignIn ? (
							<SignInForm error={error} setShowModal={setShowModal} />
						) : (
							<SignUpForm
								error={error}
								setSuccessful={setRegistrationSuccessful}
							/>
						)}
						{isSignIn ? (
							<span className={styles.AuthModalChangeModal}>
								Don’t you have an account?{' '}
								<button onClick={() => setIsSignIn(false)}>Sign up</button>
							</span>
						) : (
							<span className={styles.AuthModalChangeModal}>
								Already have an account?{' '}
								<button onClick={() => setIsSignIn(true)}>Log in</button>`
							</span>
						)}
					</>
				)}
			</div>
		</div>
	);
}
