export default function ArrowIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='8'
			viewBox='0 0 14 8'
			fill='none'
		>
			<path
				d='M1 7L7 1L13 7'
				stroke='#131722'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
