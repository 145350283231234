export const controlStyles = ({ defaultStyles, isMedia1024 }) => ({
	...defaultStyles,
	cursor: 'pointer',
	borderWidth: '1.5px',
	borderColor: '#DCE1EE',
	boxShadow: 'none',
	backgroundColor: '#fff',
	borderRadius: '8px',
	transition: 'all 0.3s',
	outline: 'none',
	userSelect: 'none',
	minHeight: '36px',
	height: '36px',
	'&:hover': {
		backgroundColor: !isMedia1024 ? '#f2f2f2' : '',
	},
});

export const selectStyles = ({ select, isMedia480 }) => ({
	control: () => ({}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	placeholder: () => ({
		color: '#131722',
		fontSize: select === 'courtsItem' && isMedia480 ? '14px' : '16px',
	}),
	singleValue: () => ({
		maxWidth: '180px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		lineHeight: '0.8',
		height: '100%',
		fontSize: select === 'courtsItem' && isMedia480 ? '12px' : '16px',
		paddingTop: '12px',
	}),
	valueContainer: () => ({
		display: 'flex',
		alignItems: 'center',
		paddingLeft: '8px',
		paddingRight: '4px',
		fontWeight: '500',
		lineHeight: 'normal',
		height: '100%',
	}),
	menuList: () => ({
		fontWeight: '500',
		color: 'black',
		width: '100%',
		backgroundColor: '#fff',
		borderRadius: '8px',
		border: 'none',
		fontSize:
			select === 'courtsItem' && isMedia480
				? '12px'
				: select === 'courtsItem'
				? '14px'
				: '16px',
	}),
});

export const menuStyles = defaultStyles => ({
	...defaultStyles,
	top: '30px',
	left: '0',
	width: '100%',
	backgroundColor: 'transparent',
	right: '-5px',
});

export const optionsStyles = defaultStyles => ({
	...defaultStyles,
	cursor: 'pointer',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	borderRadius: '8px',
	border: 'none',
});
