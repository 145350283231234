import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import styles from './PriceRangeSlider.module.scss';

function PriceRangeSlider({ minPrice, maxPrice, setMinPrice, setMaxPrice }) {
	const [range, setRange] = useState([minPrice]);

	const handleRangeChange = newRange => {
		setRange(newRange);
		setMinPrice(newRange[0]);
		setMaxPrice(newRange[1]);
	};

	useEffect(() => {
		setRange([minPrice, maxPrice]);
	}, [minPrice, maxPrice]);

	return (
		<div className={styles.PriceRangeSliderBox}>
			<div className={styles.PriceRangeSliderValues}>
				<span>${range[0]}</span>—<span>${range[1]}</span>
			</div>
			<Slider
				range
				min={minPrice}
				max={maxPrice}
				value={range}
				onChange={handleRangeChange}
				className={styles.PriceRangeSlider}
			/>
		</div>
	);
}

export default PriceRangeSlider;
