import Cookies from 'js-cookie';
import {
	checkTimeOfDay,
	extractTimeFromDateTime,
	formatDateV2,
} from '../../../../../utils/calendar';
import SuccessfulIcons from './icons/SuccessfulIcons';
import styles from './SuccessfulCompletion.module.scss';

export default function SuccessfulCompletion() {
	const slot = JSON.parse(Cookies.get('selected-slot'));

	const date = new Date(slot.value);
	const time = date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	return (
		<div className={styles.SuccessfulCompletion}>
			<div className={styles.ResultSuccessfulCompletion}>
				<SuccessfulIcons />
				<h2 className={styles.ResultSuccessfulCompletionTitle}>Great!</h2>
				<h4 className={styles.ResultSuccessfulCompletionSubTitle}>
					Your reservation was successful!
				</h4>
				<p className={styles.ResultSuccessfulCompletionInfo}>
					Please find your receipt below and additional payment confirmation
					sent to your email
				</p>
			</div>
			<div className={styles.SuccessfulCompletionCheck}>
				<h3 className={styles.SuccessfulCompletionCheckTitle}>Your receipt</h3>
				<table className={styles.SuccessfulCompletionCheckTable}>
					<td>
						<tr>Name</tr>
						<tr>{slot.courtName}</tr>
					</td>
					<td>
						<tr>Location</tr>
						<tr>{slot.courtAddress}</tr>
					</td>
					<td>
						<tr>Booking date</tr>
						<tr>{formatDateV2(new Date(slot.value))}</tr>
					</td>
					<td>
						<tr>Time</tr>
						<tr>
							<p>{slot.dayOfWeek},</p>
							<p>
								{checkTimeOfDay(slot.value)} {time} −{' '}
								{extractTimeFromDateTime(slot.finishDate)}
							</p>
						</tr>
					</td>
					<td>
						<tr>Price</tr>
						<tr>${slot.price}</tr>
					</td>
				</table>
			</div>
		</div>
	);
}
