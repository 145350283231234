import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { signUpFx } from '../../../../api/auth';
import EmailInput from '../../../ui/AuthForm/EmailInput';
import PasswordInput from '../../../ui/AuthForm/PasswordInput';
import SubmitButton from '../../../ui/AuthForm/SubmitButton';
import styles from './SignUpForm.module.scss';

export default function SignUpForm({ error, setSuccessful }) {
	const {
		register,
		formState: { errors },
		handleSubmit,
		resetField,
		control,
	} = useForm();

	const [loading, setLoading] = useState(false);

	const OnSubmit = async inputValue => {
		try {
			setLoading(true);
			const { data } = await signUpFx({
				url: '/api/v1/accounts/registration',
				email: inputValue.email,
				password: inputValue.password,
			});
			resetField('email');
			resetField('password');
			setSuccessful(true);
			toast.error(data.warningMessage);
		} catch (error) {
			toast.error('Oops! Error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={styles.SignUpFormBox}>
			<form className={styles.SignUpForm} onSubmit={handleSubmit(OnSubmit)}>
				<EmailInput register={register} errors={errors} />
				<PasswordInput register={register} errors={errors} control={control} />
				<SubmitButton loading={loading} name='Create account' />
			</form>
			{/* <span className={styles.SignUpSeparator}>or</span>
			<AuthViaSocialNetwork /> */}
		</div>
	);
}
