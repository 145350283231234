import TennisCourtsSection from '../../components/sections/TennisCourtsSection/TennisCourtsSection';
import PageTitle from '../../components/ui/Titles/PageTitle/PageTitle';
import styles from './TennisCourts.module.scss';

export function TennisCourts({ isLoggedIn }) {
	return (
		<div className='container'>
			<div className={styles.TennisCourts}>
				<PageTitle title='Tennis courts' breadcrumbs />
				<TennisCourtsSection isLoggedIn={isLoggedIn} />
			</div>
		</div>
	);
}
