export default function CrossIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='30'
			viewBox='0 0 30 30'
			fill='none'
		>
			<path
				d='M23.75 6.25L6.25 23.75M6.25002 6.25L23.75 23.75'
				stroke='#96989F'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
