import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import {
	changeEmail,
	changePassword,
	updateAccountInfo,
} from '../../../../api/account';
import { $user } from '../../../../context/user';
import ChangeEmailInput from '../../../ui/ProfileForm/ChangeEmailInput';
import FirstNameInput from '../../../ui/ProfileForm/FirstNameInput';
import LastNameInput from '../../../ui/ProfileForm/LastNameInput';
import CameraIcon from './icons/CameraIcon';
import EditIcon from './icons/EditIcon';
import LoadingPhoto from './images/loading-photo.gif';
import NoPhoto from './images/no-photo.jpg';
import styles from './MyAccount.module.scss';

export default function MyAccount({ editMode, setEditMode }) {
	const [editUserNameMode, setEditUserNameMode] = useState(false);
	const user = useStore($user);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [uploadAvatar, setUploadAvatar] = useState(user.avatar);
	const [loadingAvatar, setLoadingAvatar] = useState(false);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [repeatNewPassword, setRepeatNewPassword] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [saving, setSaving] = useState(false);
	const token = localStorage.getItem('accessToken');
	const {
		register,
		formState: { errors },
	} = useForm();

	const apiUrl = process.env.REACT_APP_SERVER_URL;

	useEffect(() => {
		setFirstName(user.first_name || '');
		setLastName(user.last_name || '');
		setUploadAvatar(user.avatar);
	}, [user.first_name, user.last_name, user.avatar]);

	const avatar = !loadingAvatar
		? uploadAvatar
			? `${apiUrl}${uploadAvatar}`
			: NoPhoto
		: LoadingPhoto;

	const name = firstName && lastName ? `${firstName} ${lastName}` : 'Your name';

	const email = user.email;
	// const age = user.age;
	// const hobbies = ['Football', 'Tennis', 'Music'];
	const decorPassword = 'qwertyuiopasdfghjklzxcvbnm';

	const handleEdit = async () => {
		setEditMode(true);
	};

	const saveEdits = async () => {
		try {
			setSaving(true);

			if (oldPassword && newPassword && repeatNewPassword) {
				await changePassword({
					url: '/api/v1/accounts/change-password',
					old_password: oldPassword,
					new_password: newPassword,
				});

				setOldPassword('');
				setNewPassword('');
				setRepeatNewPassword('');
				toast.success('Password has been changed');
				setEditMode(false);
				setEditUserNameMode(false);
				setSaving(false);
			} else if (newEmail) {
				await changeEmail({
					url: '/api/v1/accounts/email-reset',
					new_email: newEmail,
				});
				setNewEmail('');
				toast.success(
					'A confirmation email has been sent to your new email account'
				);
				setEditMode(false);
				setEditUserNameMode(false);
				setSaving(false);
			} else if (editUserNameMode) {
				await updateAccountInfo({
					url: '/api/v1/accounts/update',
					first_name: firstName,
					last_name: lastName,
				});
				toast.success('Name successfully changed');
			}

			setEditMode(false);
			setEditUserNameMode(false);
			setSaving(false);
		} catch (error) {
			console.error(error);
			toast.error(error.response.data.message);
		}
	};

	const handleAvatarChange = async e => {
		const formData = new FormData();
		formData.append('avatar', e.target.files[0]);
		try {
			setLoadingAvatar(true);
			const res = await fetch(
				'https://app.tilaa.cloud.tenniscourtsf.com/api/v1/accounts/update',
				{
					method: 'PATCH',
					body: formData,
					headers: {
						Authorization: token ? `Bearer ${token}` : '',
					},
				}
			);
			const data = await res.json();

			setUploadAvatar(data.avatar);
			toast.success('upload avatar');
		} catch (error) {
			toast.error('Failed to upload photo');
			console.error(error);
		} finally {
			setLoadingAvatar(false);
		}
	};

	return !editMode ? (
		<div className={styles.MyAccount}>
			<div className={styles.MyAccountImageBox}>
				<img className={styles.MyAccountImage} src={avatar} alt={name} />
				<button className={styles.EditProfileButton} onClick={handleEdit}>
					Edit Profile
				</button>
			</div>
			<div className={styles.MyAccountInfo}>
				<h4 className={styles.MyAccountName}>{name}</h4>
				<ul className={styles.MyAccountMoreInfoList}>
					<li className={styles.MyAccountMoreInfoListItem}>
						<span>Email</span> <span>{email}</span>
					</li>
					{/* <li className={styles.MyAccountMoreInfoListItem}>
						<span>Age</span> <span>{age} years</span>
					</li> */}
					{/* <li className={styles.MyAccountMoreInfoListItem}>
						<span>Hobbies</span>
						{hobbies.map((hobbie, index) => {
							return (
								<span key={hobbie}>
									{hobbie}
									{index < hobbies.length - 1 ? ',' : ''}
								</span>
							);
						})}
					</li> */}
				</ul>
				<div className={styles.MyAccountAccess}>
					<h4 className={styles.MyAccountAccessTitle}>Account access</h4>
					<label className={styles.AccountLabel}>
						Password
						<input type='password' readOnly value={decorPassword} />
					</label>
					<button className={styles.EditPasswordButton} onClick={handleEdit}>
						Edit password
					</button>
				</div>
			</div>
		</div>
	) : (
		<div className={styles.EditAccount}>
			<div className={styles.EditAccountImageBox}>
				<label className={styles.UploadImage}>
					<input
						type='file'
						accept='image/jpeg, image/png'
						onChange={handleAvatarChange}
					/>
					<CameraIcon />
					<span>Edit/Add image</span>
				</label>
				<img className={styles.EditAccountImage} src={avatar} alt={name} />
			</div>
			<div className={styles.EditAccountInfo}>
				<div className={styles.EditAccountNameBox}>
					{editUserNameMode ? (
						<form className={styles.EditNameForm}>
							<div className={styles.EditNameInputs}>
								<FirstNameInput
									onChange={e => setFirstName(e.target.value)}
									value={firstName}
									register={register}
									errors={errors}
								/>
								<LastNameInput
									onChange={e => setLastName(e.target.value)}
									value={lastName}
									register={register}
									errors={errors}
								/>
							</div>
						</form>
					) : (
						<h4 className={styles.EditAccountName}>{name}</h4>
					)}
					{!editUserNameMode && (
						<button
							className={styles.EditNameButton}
							onClick={() => setEditUserNameMode(true)}
						>
							<EditIcon />
						</button>
					)}
				</div>
				<ChangeEmailInput
					register={register}
					errors={errors}
					newEmail={newEmail}
					setNewEmail={setNewEmail}
				/>
				{/* <div className={styles.EditAge}>
					<h5 className={styles.AccountLabel} style={{ margin: 0 }}>
						Age
					</h5>
					<StandardSelect placeholder={age} className />
				</div> */}
				<div className={styles.EditAccountAccess}>
					<h4 className={styles.EditAccountAccessTitle}>Account access</h4>
					<label className={styles.EditAccountLabel}>
						Password
						<input
							type='password'
							value={oldPassword}
							onChange={e => setOldPassword(e.target.value)}
						/>
					</label>
					<label className={styles.EditAccountLabel}>
						New password
						<input
							type='password'
							value={newPassword}
							onChange={e => setNewPassword(e.target.value)}
						/>
					</label>
					<label className={styles.EditAccountLabel}>
						Confirm password
						<input
							type='password'
							value={repeatNewPassword}
							onChange={e => setRepeatNewPassword(e.target.value)}
						/>
					</label>
					<button
						className={styles.SavePasswordButton}
						disabled={
							(oldPassword.length > 0 && newPassword.length < 4) ||
							newPassword !== repeatNewPassword ||
							(newEmail.length > 0 && !isEmail(newEmail))
						}
						onClick={saveEdits}
					>
						{saving ? 'Saving...' : 'Save'}
					</button>
				</div>
			</div>
		</div>
	);
}
