import ProfileTitle from '../../../ui/Titles/ProfileTitle/ProfileTitle';
import PlusIcon from '../PaymentInfoCard/icons/PlusIcon';
import styles from './PaymentInfo.module.scss';

export default function PaymentInfo() {
	return (
		<div>
			<ProfileTitle title='Payment info' />
			<div className={styles.PaymentInfoText}>
				<p>
					For convenient payment of tennis court reservations, please follow
					these instructions:
				</p>
				<p>Choose the date and time for your game.</p>
				<p>
					Contact us using the phone number or email provided on the contact
					page to confirm court availability and secure your reservation.
				</p>
				<p>
					After your reservation is confirmed, we will provide you with payment
					instructions, including banking details or electronic payment methods.
				</p>
				<p>
					Follow the instructions to select your preferred payment method and
					complete the transaction.
				</p>
				<p>
					Upon successful payment, you will receive a reservation confirmation
					via email.
				</p>
				<p>
					Thank you for choosing our courts, and we guarantee you an excellent
					time on the tennis court!
				</p>
			</div>
			{/* <ul className={styles.PaymentInfoCardList}>
				<PaymentInfoCard
					number='4048 0250 0149 8282'
					name='CARDHOLDER NAME'
					month='12'
					year='24'
				/>
				<PaymentInfoCard
					number='5106 2680 3518 3830'
					name='CARDHOLDER NAME'
					month='04'
					year='25'
				/>
			</ul> */}
			<button className={styles.AddMethodButton}>
				<PlusIcon />
				Add payment method
			</button>
		</div>
	);
}
