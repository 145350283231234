import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllProducts } from '../../../../api/courts';
import { setPageSizeInUrl } from '../../../../utils/common';
import TennisCourtsItem from '../TennisCourtsItem/TennisCourtsItem';
import animationData from './images/loading.json';
import styles from './TennisCourtsList.module.scss';

export default function TennisCourtsList({
	courts,
	setCourts,
	newCourts,
	setNewCourts,
	isLoggedIn,
	url,
	newCourtsSize,
}) {
	const [isLoadingNewCourts, setIsLoadingNewCourts] = useState(false);
	const [isLoadingCourts, setIsLoadingCourts] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoadingCourts(true);
				const { data } = await getAllProducts({
					url: url,
				});

				setCourts(data.results);
				setNewCourts(setPageSizeInUrl(data.next, newCourtsSize));
				setIsLoadingCourts(false);
			} catch (error) {
				toast.error('Failed to load new courts');
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const handleShowMoreClick = async () => {
		try {
			setIsLoadingNewCourts(true);
			const { data } = await getAllProducts({
				url: newCourts,
			});
			setCourts(prevCourts => [...prevCourts, ...data.results]);
			setNewCourts(setPageSizeInUrl(data.next, newCourtsSize));
		} catch (error) {
			toast.error('Failed to load new courts');
			console.error(error);
		} finally {
			setIsLoadingNewCourts(false);
		}
	};

	return isLoadingCourts ? (
		<Lottie
			animationData={animationData}
			loop={true}
			autoplay={true}
			className={styles.TennisCourtsListLoading}
		/>
	) : (
		<div>
			<div className={styles.TennisCourtsList}>
				{courts && courts?.length ? (
					courts.map((court, index) => (
						<TennisCourtsItem
							key={index}
							preview={court.image}
							name={court.title}
							address={court.location.address}
							authorName={court.owner.full_name}
							authorImage={court.owner.avatar}
							slots={court.slots}
							isLoggedIn={isLoggedIn}
						/>
					))
				) : (
					<span className={styles.TennisCourtsListNotFound}>
						There are no courts.
					</span>
				)}
			</div>
			{newCourts && (
				<button
					className={styles.TennisCourtsListMoreButton}
					onClick={handleShowMoreClick}
					disabled={isLoadingNewCourts}
				>
					{!isLoadingNewCourts ? 'Show more' : 'Loading...'}
				</button>
			)}
		</div>
	);
}
