export default function SuccessfulIcons() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='100'
			height='100'
			viewBox='0 0 100 100'
			fill='none'
		>
			<path
				d='M49.9997 8.33333C27.083 8.33333 8.33301 27.0833 8.33301 50C8.33301 72.9167 27.083 91.6667 49.9997 91.6667C72.9163 91.6667 91.6664 72.9167 91.6664 50C91.6664 27.0833 72.9163 8.33333 49.9997 8.33333ZM67.4997 42.9167L47.4997 62.9167C45.833 64.5833 43.333 64.5833 41.6663 62.9167L32.4997 53.75C30.833 52.0833 30.833 49.5833 32.4997 47.9167C34.1663 46.25 36.6663 46.25 38.333 47.9167L44.583 54.1667L61.6663 37.0833C63.333 35.4167 65.833 35.4167 67.4997 37.0833C69.1663 38.75 69.1663 41.25 67.4997 42.9167Z'
				fill='#2DBF60'
			/>
		</svg>
	);
}
