import { toast } from 'react-toastify';
import styles from './ProfileForm.module.scss';

export default function LastNameInput({ onChange, value, register, errors }) {
	return (
		<>
			<input
				{...register('lastName', {
					required: 'Enter the last name.',
					minLength: 2,
					maxLength: 32,
				})}
				className={styles.NameInput}
				type='text'
				onChange={onChange}
				value={value}
				placeholder='Last name'
			/>
			{errors.lastName && toast.error(errors.lastName.message)}
		</>
	);
}
