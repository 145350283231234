import { Controller } from 'react-hook-form';
import styles from './AuthForm.module.scss';

export default function PasswordInput({ register, errors, control, isLogin }) {
	return (
		<label className={styles.Label}>
			Password
			<Controller
				name='password'
				control={control}
				defaultValue=''
				rules={{
					required: 'Please enter a password.',
					minLength: 6,
					maxLength: 32,
				}}
				render={({ field }) => (
					<div className={styles.PasswordInput}>
						<input
							{...field}
							{...register('password', {
								required: 'Please enter a password.',
								minLength: 6,
								maxLength: 32,
							})}
							onChange={e => field.onChange(e.target.value)}
							maxLength={32}
							className={`${styles.Input} ${styles.PasswordInput}`}
						/>
						{errors.password && (
							<span className={styles.ErrorAlert}>
								{errors.password.message}
							</span>
						)}
						{errors.password && errors.password.type === 'minLength' && (
							<span className={styles.ErrorAlert}>Minimum of 6 symbols</span>
						)}
						{errors.password && errors.password.type === 'maxLength' && (
							<span className={styles.ErrorAlert}>Maximum of 32 symbols</span>
						)}
					</div>
				)}
			/>
			{isLogin && (
				<>
					<button className={styles.ResetPasswordButton} type='button'>
						I forgot my password
					</button>
					<label className={styles.StayLoggedInCheckbox}>
						Stay logged in
						<input type='checkbox' />
					</label>
				</>
			)}
		</label>
	);
}
