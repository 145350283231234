import { toast } from 'react-toastify';
import styles from './ProfileForm.module.scss';

export default function FirstNameInput({ onChange, value, register, errors }) {
	return (
		<>
			<input
				{...register('firstName', {
					required: 'Enter the first name.',
					minLength: 2,
					maxLength: 32,
				})}
				className={styles.NameInput}
				type='text'
				onChange={onChange}
				value={value}
				placeholder='First name'
			/>
			{errors.firstName && toast.error(errors.firstName.message)}
		</>
	);
}
