import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import StandardSelect from '../../../ui/Selects/StandardSelect/StandardSelect';
import styles from './TennisCourtsItem.module.scss';

import Cookies from 'js-cookie';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import {
	extractTimeFromDateTime,
	formatDateV4,
} from '../../../../utils/calendar';
import NoAvatarImage from './images/no-avatar.jpg';

export default function TennisCourtsItem({
	preview,
	name,
	address,
	authorName,
	authorImage,
	slots,
	isLoggedIn,
}) {
	const isMedia960 = useMediaQuery(960);

	const options = slots.map(slot => {
		const { fullDate, shortDate } = formatDateV4(slot.date_start);
		return {
			value: slot.date_start,
			label: `${isMedia960 ? shortDate : fullDate}, ${extractTimeFromDateTime(
				slot.date_start
			)} - ${extractTimeFromDateTime(slot.date_finish)}`,
			price: slot.price,
			finishDate: slot.date_finish,
			id: slot.slot_id,
			dayOfWeek: slot.day_of_week,
			courtAddress: address,
			courtName: name,
			fullDate,
			shortDate,
		};
	});

	const [selectedOption, setSelectedOption] = useState(null);
	const isMedia768 = useMediaQuery(768);

	const handleOptionChange = selected => {
		setSelectedOption(selected);
		Cookies.set('selected-slot', JSON.stringify(selected), { expires: 7 });
	};

	return (
		<div className={styles.TennisCourtsItem}>
			<img className={styles.TennisCourtsItemImage} src={preview} alt={name} />
			<div className={styles.TennisCourtsItemInner}>
				<h5 className={styles.TennisCourtsItemName}>{name}</h5>
				<div>
					<span className={styles.TennisCourtsItemAddress}>{address}</span>
					<div className={styles.TennisCourtsItemAuthor}>
						<img
							className={styles.TennisCourtsItemAuthorImage}
							src={authorImage || NoAvatarImage}
							alt={authorName.trim() ? authorName : 'Free user'}
						/>
						<span className={styles.TennisCourtsItemAuthorName}>
							{authorName.trim() ? authorName : 'Free user'}
						</span>
					</div>
					<StandardSelect
						options={options}
						onChange={handleOptionChange}
						placeholder='Select time'
						select='courtsItem'
					/>
					<div className={styles.TennisCourtsItemOrderBox}>
						{slots.length > 0 && (
							<div className={styles.TennisCourtsItemPriceBox}>
								{isMedia768 ? <span>Price</span> : ''}
								<span className={styles.TennisCourtsItemPrice}>
									${selectedOption ? selectedOption.price : slots[0].price}
								</span>
							</div>
						)}
						<Link
							disabled={!selectedOption}
							className={`${styles.TennisCourtsItemBookButton} ${
								!selectedOption ? styles.TennisCourtsItemBookDisabledButton : ''
							}`}
							to={isLoggedIn ? '/payment' : ''}
							onClick={() => !isLoggedIn && toast.warning('Please log in')}
						>
							Book Now
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
