import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllFilters, getAllProducts } from '../../../../api/courts';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { formatDateV3, formatDateV5 } from '../../../../utils/calendar';
import { setPageSizeInUrl } from '../../../../utils/common';
import PriceRangeSlider from '../../../ui/PriceRangeSlider/PriceRangeSlider';
import StandardSelect from '../../../ui/Selects/StandardSelect/StandardSelect';
import ArrowIcon from './icons/ArrowIcon';

import styles from './TennisCourtsFilters.module.scss';

export default function TennisCourtsFilters({
	url,
	setCourts,
	setNewCourts,
	newCourtsSize,
}) {
	const [minPrice, setMinPrice] = useState(null);
	const [maxPrice, setMaxPrice] = useState(null);
	const [locations, setLocations] = useState(null);
	const [locationId, setLocationId] = useState(null);
	const [dates, setDates] = useState(null);
	const [date, setDate] = useState(null);
	const [timeOfDay, setTimeOfDay] = useState('morning');
	const [filters, setFilters] = useState(null);
	const [showMobileFilters, setShowMobileFilters] = useState(false);
	const isMedia960 = useMediaQuery(960);
	const [filtrationLoading, setFiltrationLoading] = useState(false);

	const TimeOfDayOptions = [
		{ value: 'morning', label: 'Morning' },
		{ value: 'afternoon', label: 'Afternoon' },
		{ value: 'evening', label: 'Evening' },
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await getAllFilters({
					url: '/api/v1/product/slots/filter-data',
				});

				setFilters(data);
				setLocations(data.locations);
				setDates(data.properties.dates);
			} catch (error) {
				toast.error('Failed to load new courts');
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const locationOptions = locations
		? locations.map(location => ({
				value: location.id,
				label: location.title,
		  }))
		: [];

	const datesOptions = dates
		? dates.map(date => ({
				value: formatDateV3(date),
				label: formatDateV5(date),
		  }))
		: [];

	const applySelectedFilters = async () => {
		try {
			setFiltrationLoading(true);

			if (minPrice !== null) {
				url += `&price_min=${minPrice}`;
			}

			if (maxPrice !== null) {
				url += `&price_max=${maxPrice}`;
			}

			if (locationId !== null) {
				url += `&location_id=${locationId}`;
			}

			if (date !== null) {
				url += `&date=${date}`;
			}

			if (timeOfDay !== null) {
				url += `&time_of_day=${timeOfDay}`;
			}

			const { data } = await getAllProducts({ url });
			setCourts(data.results);
			setFiltrationLoading(false);
			setNewCourts(setPageSizeInUrl(data.next, newCourtsSize));
		} catch (error) {
			toast.error('Failed to load new courts');
			console.error(error);
		}
	};

	return (
		<div className={styles.TennisCourtsFilters}>
			<div
				className={styles.TennisCourtsFiltersTop}
				onClick={() => isMedia960 && setShowMobileFilters(!showMobileFilters)}
				style={{ cursor: isMedia960 ? 'pointer' : '' }}
			>
				<h4 className={styles.TennisCourtsFiltersTitle}>Select Options:</h4>
				{isMedia960 && (
					<button
						className={styles.OpenMobileFiltersButton}
						style={{
							transform: showMobileFilters ? 'rotate(180deg)' : 'rotate(0deg)',
						}}
					>
						<ArrowIcon />
					</button>
				)}
			</div>

			{(showMobileFilters || !isMedia960) && (
				<div className={styles.TennisCourtsFiltersBox}>
					<div className={styles.TennisCourtsTimeFilter}>
						<h5 className={styles.TennisCourtsFilterTitle}>
							Choose a date and time
						</h5>
						<div className={styles.TennisCourtsTimeFilterSelects}>
							<StandardSelect
								placeholder='Select date'
								options={datesOptions.reverse()}
								onChange={selectedOption => {
									setDate(selectedOption.value);
								}}
							/>
							<StandardSelect
								placeholder='Morning'
								options={TimeOfDayOptions}
								onChange={selectedOption => {
									setTimeOfDay(selectedOption.value);
								}}
								disabled={!date}
								onClick={() => !date && toast.warning('Please select a date')}
							/>
						</div>
					</div>
					<div className={styles.TennisCourtsLocationFilter}>
						<h5 className={styles.TennisCourtsFilterTitle}>
							Choose a location
						</h5>
						<StandardSelect
							options={locationOptions}
							placeholder='All locations'
							onChange={selectedOption => {
								setLocationId(selectedOption.value);
							}}
						/>
					</div>
					<div className={styles.TennisCourtsPriceFilter}>
						<h5 className={styles.TennisCourtsFilterTitle}>Price</h5>
						<PriceRangeSlider
							minPrice={filters?.properties?.price_min}
							maxPrice={filters?.properties?.price_max}
							setMinPrice={setMinPrice}
							setMaxPrice={setMaxPrice}
						/>
					</div>
					<button
						className={styles.TennisCourtsFiltersApplyButton}
						onClick={applySelectedFilters}
					>
						{filtrationLoading ? 'Loading...' : 'Apply'}
					</button>
				</div>
			)}
		</div>
	);
}
