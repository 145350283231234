export const dayNames = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
	'Sun',
];

const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export function formatDate(inputDate) {
	// 02.11.2023 to November 02, 2023
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return new Date(inputDate).toLocaleDateString('en-US', options);
}

export function formatDateV2(inputDate) {
	// 02.11.2023 to 2 November 2023
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return new Date(inputDate).toLocaleDateString('en-GB', options);
}

export function formatDateV3(inputDate) {
	// 02/11/2023 to 2023-11-02
	const parts = inputDate.split('/');

	const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];

	return formattedDate;
}

export function formatDateV4(inputDate) {
	// 2023/11/02 to Nov 2, 2023
	const dateParts = inputDate.split('/');

	if (dateParts.length === 3) {
		const year = dateParts[0];
		const month = monthNames[parseInt(dateParts[1], 10) - 1];
		const day = parseInt(dateParts[2], 10);

		const fullDate = `${month} ${day}, ${year}`;
		const shortDate = `${month} ${day}`;

		return { fullDate, shortDate };
	}

	return 'Invalid date format';
}

export function formatDateV5(inputDate) {
	// 02/11/2023 to 11/2/2023
	const dateParts = inputDate.split('/');

	let day = dateParts[0];
	let month = dateParts[1];
	const year = dateParts[2];

	day = parseInt(day, 10).toString();
	month = parseInt(month, 10).toString();

	const formattedDate = `${month}/${day}/${year}`;

	return formattedDate;
}

export function checkTimeOfDay(timeString) {
	const date = new Date(timeString);
	const hours = date.getHours();

	if (hours >= 5 && hours < 12) {
		return 'morning';
	} else if (hours >= 12 && hours < 16) {
		return 'afternoon';
	} else {
		return 'evening';
	}
}

export function extractTimeFromDateTime(dateTimeString) {
	const dateTime = new Date(dateTimeString);
	const hours = dateTime.getHours();
	const minutes = dateTime.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';

	// Converts the clock to 12-hour format
	const formattedHours = hours % 12 || 12;

	// Complements with zeros if minutes < 10
	const formattedMinutes = String(minutes).padStart(2, '0');

	return `${formattedHours}:${formattedMinutes} ${ampm}`;
}
