import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import useRefreshToken from '../../../hooks/useRefreshToken';
import BookingHistory from '../../modules/ProfilePage/BookingHistory/BookingHistory';
import MyAccount from '../../modules/ProfilePage/MyAccount/MyAccount';
import PaymentInfo from '../../modules/ProfilePage/PaymentInfo/PaymentInfo';
import ArrowIcon from './icons/ArrowIcon';
import styles from './ProfileSection.module.scss';

const tabs = [
	{ title: 'My account' },
	{ title: 'Booking history' },
	{ title: 'Payment info' },
	// { title: 'FAQ' },
];

export default function ProfileSection() {
	const isMedia768 = useMediaQuery(768);
	const [showMobileSidebar, setShowMobileSidebar] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [activeTab, setActiveTab] = useState('My account');

	const handleTabChange = tabIndex => {
		const selectedTabTitle = tabs[tabIndex].title;
		setActiveTab(selectedTabTitle);
	};

	const handleLogout = () => {
		localStorage.clear();
		window.location.reload();
		Cookies.remove('refresh_token');
	};

	useRefreshToken();

	useEffect(() => {
		if (!isMedia768) {
			setShowMobileSidebar(true);
		} else {
			setShowMobileSidebar(false);
		}
	}, [isMedia768]);

	const handleTabClick = e => {
		if (isMedia768) {
			setShowMobileSidebar(!showMobileSidebar);
		}
	};

	return (
		<section className={styles.ProfileSection}>
			<Tabs
				className={styles.ProfileInfo}
				selectedIndex={tabs.findIndex(tab => tab.title === activeTab)}
				onSelect={handleTabChange}
			>
				<TabList className={styles.ProfileSidebar} role='tablist'>
					{isMedia768 && (
						<button
							className={`${styles.ProfileSidebarItem} ${styles.ProfileSidebarItemActive}`}
							onClick={handleTabClick}
						>
							{activeTab}
							<ArrowIcon active={showMobileSidebar} />
						</button>
					)}
					{showMobileSidebar && (
						<>
							{tabs.map((tab, index) => (
								<Tab
									key={index}
									className={styles.ProfileSidebarItem}
									selectedClassName={
										!isMedia768 && styles.ProfileSidebarItemActive
									}
									onClick={() => {
										handleTabClick();
										index === 0 && setEditMode(false);
									}}
								>
									{tab.title}
								</Tab>
							))}
							<Tab className={styles.ProfileSidebarItem} onClick={handleLogout}>
								Log out
							</Tab>
						</>
					)}
				</TabList>

				<TabPanel>
					{activeTab === 'My account' && (
						<MyAccount editMode={editMode} setEditMode={setEditMode} />
					)}
				</TabPanel>
				<TabPanel>
					{activeTab === 'Booking history' && <BookingHistory />}
				</TabPanel>
				<TabPanel>{activeTab === 'Payment info' && <PaymentInfo />}</TabPanel>
				{/* <TabPanel>{activeTab === 'FAQ' && <FAQ />}</TabPanel> */}
			</Tabs>
		</section>
	);
}
