import ProfileTitle from '../../../ui/Titles/ProfileTitle/ProfileTitle';
import BookingHistoryListItem from '../BookingHistoryListItem/BookingHistoryListItem';
import styles from './BookingHistory.module.scss';
import CalendarIcon from './icons/CalendarIcon';

export default function BookingHistory() {
	const show = false;
	return (
		<div className={styles.BookingHistory}>
			<div className={styles.BookingHistoryTop}>
				<ProfileTitle title='Booking history' />
				{show && (
					<button className={styles.BookingHistorySelectDateButton}>
						<CalendarIcon />
						Select Date
					</button>
				)}
			</div>
			{show ? (
				<ul className={styles.BookingHistoryList}>
					<BookingHistoryListItem
						name='Laboratorio de Cómputos Pequeño'
						address='Dewey University - Carolina Campus, Carolina, PR, USA'
						date='04/09/2023'
						time='Morning, 10:00am'
						price='180'
					/>
				</ul>
			) : (
				<span>There's nothing so far.</span>
			)}
		</div>
	);
}
