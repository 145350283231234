import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createOrder } from '../../../../api/payments';
import CheckoutForm from '../../Forms/CheckoutForm/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripePayment({ isPage, slotId }) {
	const [clientSecret, setClientSecret] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const { data } = await createOrder({
					url: '/api/v1/order/create',
					slot: slotId,
				});
				setClientSecret(data.client_secret);
			} catch (error) {
				toast.error('The selected slot does not exist');
			}
		};

		fetchData();
	}, []);

	return (
		<>
			{stripePromise && clientSecret && (
				<Elements stripe={stripePromise} options={{ clientSecret }}>
					<CheckoutForm isPage={isPage} />
				</Elements>
			)}
		</>
	);
}
