export default function CrossIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				d='M18.75 1.25L1.25 18.75M1.25002 1.25L18.75 18.75'
				stroke='#96989F'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
