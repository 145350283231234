import { Link } from 'react-router-dom';
import styles from './PageTitle.module.scss';

export default function PageTitle({ title, breadcrumbs }) {
	return (
		<>
			<h2 className={styles.PageTitle}>{title}</h2>
			{breadcrumbs && (
				<ul className={styles.Breadcrumbs}>
					<li className={styles.BreadcrumbsItem}>
						<Link to='/'>Home</Link>
					</li>
					<li className={styles.BreadcrumbsItem}>
						<Link to='/tennis-courts'>{title}</Link>
					</li>
				</ul>
			)}
		</>
	);
}
